// React
import React, { useState, useContext } from "react";

// UI Components
import { Col, Row, Modal, Input, message, Typography, Checkbox } from "antd";

// Third Party Libraries
import axios from "axios";

// Hooks
import useGVCoreConfig from "../../hooks/useGVCoreConfig";

// Context
import { BaseStateContext } from "../../App";

// Types
import { tmProps } from "../../types";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

// Destructuring
const { TextArea } = Input;
const { Title } = Typography;

// A modal which allows the user to create a ticket.
// The ticket will be send to the api, which will store
// it in the MySQL database.
function TicketModal(props: tmProps) {
  const { modal, setModal, appversion } = props;
  const [gvcoreConfig] = useGVCoreConfig();
  const [title, setTitle] = useState("");
  const [reproduction, setReproduction] = useState("");
  const [expected, setExpected] = useState("");
  const [actual, setActual] = useState("");
  const [browsers, setBrowsers] = useState<any[]>([]);
  const { baseState } = useContext(BaseStateContext);
  const clear = () => {
    setTitle("");
    setReproduction("");
    setExpected("");
    setActual("");
    setBrowsers([]);
  };

  const createTicket = () => {
    axios
      .post(`https://${baseState.server}/api/server/apis/createTicket.php`, {
        dbname: baseState.dbname,
        key: baseState.key,
        config: JSON.stringify(gvcoreConfig),
        version: appversion,
        title: title,
        reproduction: reproduction,
        expected: expected,
        actual: actual,
        browsers: JSON.stringify(browsers),
        kunde: baseState.orderingUser,
      })
      .then((response) => {
        if (response.data.code === 200) {
          message.success("Fehler wurde gemeldet");
        } else {
          message.error("Es ist ein Fehler aufgetreten");
        }
      });
  };

  return (
    <Modal
      visible={modal}
      onCancel={() => {
        setModal(false);
        clear();
      }}
      onOk={() => {
        createTicket();
        clear();
        setModal(false);
      }}
      okText="Absenden"
      cancelText="Abbrechen"
    >
      <Row>
        <Col>
          <Title level={4}>Fehler melden</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input
            maxLength={100}
            onChange={({ target: { value } }) => setTitle(value)}
            width="100%"
            placeholder="Titel"
            value={title}
          ></Input>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>Reproduktion</Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextArea
            maxLength={500}
            onChange={({ target: { value } }) => setReproduction(value)}
            value={reproduction}
            placeholder="Beschreiben Sie bitte die Schritte, die notwendig sind um den Fehler zu reproduzieren"
          ></TextArea>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>Erwartetes Ergebnis</Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextArea
            value={expected}
            maxLength={500}
            onChange={({ target: { value } }) => setExpected(value)}
            placeholder="Beschreiben Sie bitte, welches Verhalten Sie erwarten"
          ></TextArea>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>Fehlerhaftes Ergebnis</Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextArea
            maxLength={500}
            value={actual}
            onChange={({ target: { value } }) => {
              setActual(value);
            }}
            placeholder="Beschreiben Sie bitte den auftretenden Fehler möglichst genau"
          ></TextArea>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>Verwendeter Browser</Col>
      </Row>
      <Row>
        <Col span={24}>
          <Checkbox.Group
            options={[
              { label: "Firefox", value: "Firefox" },
              { label: "Chrome", value: "Chrome" },
              { label: "Internet Explorer", value: "Internet Explorer" },
              { label: "Edge", value: "Edge" },
              { label: "Safari", value: "Safari" },
            ]}
            onChange={(values: CheckboxValueType[]) => {
              setBrowsers(values);
            }}
            value={browsers}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default TicketModal;
