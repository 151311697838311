// React
import React, { useState, useContext } from "react";

// UI Components
import { Col, Row, Modal, Input, message, Typography } from "antd";

// Third Party Libraries
import axios from "axios";

// Context
import { BaseStateContext } from "../../App";

// Types
import { fmProps } from "../../types";

// Destructuring
const { TextArea } = Input;
const { Title } = Typography;

/**
 * A modal which allows the user to give feedback.
The feedback will be send to the api, which
will store it in the MySQL database.
 */
function FeedbackModal(props: fmProps) {
  const { modal, setModal } = props;
  const [feedback, setFeedback] = useState("");
  const { baseState } = useContext(BaseStateContext);

  /**
   * Send the feedback data to the gvc api
   * @param feedbackString The string which will be stored in the db
   * @param user The user who gives the feedback
   */
  const sendFeedback = (feedbackString: string, user: string) => {
    axios
      .post(`https://${baseState.server}/api/server/apis/sendFeedback.php`, {
        dbname: baseState.dbname,
        key: baseState.key,
        feedback: feedbackString,
        kunde: user,
      })
      .then((response) => {
        if (response.data.code === 200) {
          message.success("Vielen Dank für das Feedback");
        } else {
          message.error("Es ist ein Fehler aufgetreten");
        }
      });
  };

  return (
    <Modal
      visible={modal}
      onCancel={() => {
        setModal(false);
        setFeedback("");
      }}
      onOk={() => {
        sendFeedback(feedback, baseState.orderingUser);
        setFeedback("");
        setModal(false);
      }}
      okText="Absenden"
      cancelText="Abbrechen"
    >
      <Row>
        <Col>
          <Title level={4}>Feedback</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextArea
            maxLength={500}
            onChange={({ target: { value } }) => setFeedback(value)}
            value={feedback}
            placeholder="Geben Sie hier ihre Nachricht an die Küche ein"
          ></TextArea>
        </Col>
      </Row>
      <br />
    </Modal>
  );
}

export default FeedbackModal;
