import React, { useContext } from "react";
import { Row, Col } from "antd";
import { customAppGetParameter } from "../../../types";
import { BaseStateContext } from "../../../App";
import { useLocation } from "react-router";

function CustomApp(props: {
  url: string;
  getParameter: customAppGetParameter;
}) {
  const location = useLocation();
  const { baseState } = useContext(BaseStateContext);
  const createNewURL = (url: string, getParameter: customAppGetParameter) => {
    if (!getParameter.active) {
      return url;
    }
    const urlHasAlreadyParameters = url.split("?").length > 1;
    let parameterValue = "";
    switch (getParameter.value) {
      case "user":
        parameterValue = baseState.orderingUser;
        break;
      default:
        parameterValue = "";
        break;
    }
    return urlHasAlreadyParameters
      ? `${url}&${
          getParameter.name
        }=${parameterValue}&category=${location.search.slice(-1)}&tan=${baseState.tan}&dbname=${baseState.dbname}`
      : `${url}?${
          getParameter.name
        }=${parameterValue}&category=${location.search.slice(-1)}&tan=${baseState.tan}&dbname=${baseState.dbname}`;
  };
  return (
    <React.Fragment>
      <Row gutter={16} justify="center">
        <Col span={24}>
          <iframe allow="clipboard-read; clipboard-write"
            style={{ width: "100%", height: 800, border: 0 }}
            src={createNewURL(props.url, props.getParameter)}
            title=""
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default CustomApp;
