import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function Loading() {
  return (
    <React.Fragment>
      <Spin
        indicator={
          <LoadingOutlined style={{ marginTop: 100, fontSize: 40 }} spin />
        }
      />
      <br />
      Lade...
    </React.Fragment>
  );
}
