import React from "react";
import Loading from "./loading";
interface LoadingOverlayProps {
  isLoading: boolean;
}
export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isLoading,
}) => {
  return isLoading ? (
    <div
      style={{
        backgroundColor: "white",
        opacity: 0.9,
        zIndex: 999,
        overflow: "auto",
        height: "120%",
        width: "120%",
        position: "fixed",
      }}
    >
      <Loading />
    </div>
  ) : null;
};
