// React
import React, { useContext, useState } from "react";

// Context
import { BaseStateContext } from "../App";

// UI Components
import { Row, Col, Modal, Carousel, Typography, Divider } from "antd";

// Hooks
import useAdminNews from "../hooks/useAdminNews";

// Types
import { newsItem } from "../types";

// Destructuring
const { Text, Title } = Typography;

// AdminNews: Modal which renders news published by the
// administrator of gvcore
const AdminNews = () => {
  const { baseState } = useContext(BaseStateContext);
  const [newsModalVisible, setNewsModalVisible] = useState(true);
  const adminNews = useAdminNews();

  return adminNews.length === 0 ? null : (
    <Modal
      visible={newsModalVisible}
      onCancel={() => setNewsModalVisible(false)}
      footer={null}
    >
      <Row justify="center">
        <Col>
          <img
            alt=""
            style={{ height: "50px" }}
            src={`/images/GVClogos/gvclogo.jpg`}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Title level={4}>Neuigkeiten</Title>
      </Row>
      <Row style={{ marginTop: 30 }} justify="space-around">
        <Col span={20}>
          <Carousel
            autoplay
            autoplaySpeed={7000}
            speed={1500}
            dots={{ className: "carouselDots" }}
          >
            {adminNews.map((newsItem: newsItem) => (
              <div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "3px",
                  }}
                >
                  <h3>{newsItem.title}</h3>
                  <Divider />
                  <Text>{newsItem.news}</Text>
                  <img
                    alt=""
                    style={{ marginTop: 15 }}
                    width="100%"
                    src={`${baseState.gvcoreConfigUrl}/pic/news/News-${newsItem.id}-1.jpg`}
                  ></img>
                </div>
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Modal>
  );
};

export default AdminNews;
