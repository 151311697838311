import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

const useDirectLink = () => {
  const history = useHistory();
  const location = useLocation();
  const app = queryString.parse(location.search).app as string;
  const auth = queryString.parse(location.search).auth as string;
  const tan = queryString.parse(location.hash).tan as string;

  useEffect(() => {
    if (app && auth && tan) {
      if (app !== "gvcore") {
        history.push(`/gvcore/${app}`);
      } else {
        history.push("/gvcore");
      }
    }
  }, [app, auth, tan, history]);
  if (app && auth && tan) {
    return [
      app as string,
      auth.split("-")[0].toLowerCase() as string,
      tan as string,
    ];
  } else {
    return false;
  }
};

export default useDirectLink;
