import { useState, useContext, useEffect } from "react";
import { BaseStateContext } from "../App";
import axios from "axios";

export default function useAdImages() {
  const [adImages, setAdImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const { baseState } = useContext(BaseStateContext);

  useEffect(() => {
    setLoading(true);
    axios
      .post(`https://${baseState.server}/api/server/apis/listAdFiles.php`, {
        key: baseState.key,
        authfactor: `${baseState.authfactor.toUpperCase()}`,
        parent: baseState.parent
      })
      .then((response) => {
        const files: string[] = response.data.files;

        setAdImages(files ?? []);
        setLoading(false);
      });
  }, [baseState.server, baseState.key]);
  return [adImages, loading] as const;
}
